import React, { ReactElement } from 'react';

import styles from './WinnersOfLuck.module.css';
import Title, { TitleTypes } from '../../../../../../components/Title/Title';
import WinnersListButton from '../WinnersListButton';

const WinnersOfLuck = (): ReactElement => (
  <div className={styles.wrapper}>
    <Title type={TitleTypes.h2} text="Майстри удачі" />
    <WinnersListButton />
  </div>
);

export default WinnersOfLuck;
