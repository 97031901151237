import React, { ReactElement } from 'react';

import Title, { TitleTypes } from '../../../../components/Title/Title';
import styles from './CardsCount.module.css';

type Props = {
  collectedCount?: number;
};

const CardsCount = ({ collectedCount }: Props): ReactElement => (
  <div className={styles.titleContainer}>
    <Title type={TitleTypes.h1} text="Мої винагороди" />
    <div className={styles.cardCollected}>
      <span className={styles.cardCollectedBold}>{collectedCount}</span>
      <span className={styles.cardCollectedRegular}>/28</span>
    </div>
    <div className={styles.text}>Зібрано карток</div>
  </div>
);

export default CardsCount;
