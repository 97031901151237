import React, { EventHandler, MouseEventHandler, ReactElement } from 'react';

import Button from '../../../../../../components/Button/Button';
import { WHITE_BIT_LINKS } from '../../../../../../consts';
import { AchievementTypes } from '../../../../../../types';
import styles from '../../Achievement.module.css';

type Props = {
  completed: boolean;
  type: AchievementTypes;
  eventIsActive: boolean;
  rewardUrl?: string | null;
};

const GiftButton = ({ completed, rewardUrl, type, eventIsActive }: Props): ReactElement | null => {
  const isWelcomeBonus = type === AchievementTypes.WELCOME_BONUS;

  const handleClickButton = (event: React.MouseEvent): void => {
    event.preventDefault();
    window.open(encodeURI(rewardUrl || WHITE_BIT_LINKS.eventInfo), '_blank');
  };

  return (
    <div className={styles.buttonWrapper} data-testid="giftButtonWrapper">
      {isWelcomeBonus && eventIsActive && <Button text="Як отримати приз?" onClick={handleClickButton} />}
      {completed && <Button text={rewardUrl ? 'Отримати приз' : 'Як отримати приз?'} onClick={handleClickButton} />}
    </div>
  );
};

export default GiftButton;
