import classNames from 'classnames';
import React from 'react';

import Title, { TitleTypes } from '../../components/Title/Title';
import styles from './Policies.module.css';

export enum TitleSizes {
  main = 'main',
  subtitle = 'subtitle',
}

type TextBlockProps = {
  title?: string;
  titleSize?: TitleSizes;
  text?: string | React.ReactNode;
  extraPadding?: boolean;
};

type HeaderProps = {
  type: TitleSizes;
  text: string;
};

const Header = ({ type, text }: HeaderProps): React.ReactElement => (
  <div className={classNames(styles[type])}>
    <Title type={type === 'main' ? TitleTypes.h2 : TitleTypes.h3} text={text} />
  </div>
);

const TextBlock = ({ title, titleSize, text, extraPadding = false }: TextBlockProps): React.ReactElement => (
  <>
    {title && <Header type={titleSize as HeaderProps['type']} text={title} />}
    {text && (
      <p
        className={classNames(styles['paragraph'], {
          [styles['paragraph-gap']]: extraPadding,
        })}
      >
        {text}
      </p>
    )}
  </>
);

export default TextBlock;
