import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type HasOpenedModals = {
  value: boolean;
};

const initialState: HasOpenedModals = {
  value: false,
};

const hasOpenedModals = createSlice({
  name: 'hasOpenedModals',
  initialState,
  reducers: {
    setHasOpenedModals(state, action: PayloadAction<boolean>) {
      state.value = action.payload;
    },
  },
});

export const { setHasOpenedModals } = hasOpenedModals.actions;

export default hasOpenedModals.reducer;
