import React from 'react';

import { ReactComponent as QRScannerButton } from '../../../../../assets/images/QRScannerButton.svg';
import styles from './StartButton.module.css';

type StartButtonProps = {
  onClick: () => void;
};

const StartButton = ({ onClick }: StartButtonProps): React.JSX.Element => (
  <div className={styles.container} onClick={onClick}>
    <QRScannerButton />
  </div>
);

export default StartButton;
