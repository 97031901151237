import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Gear } from '../../../../assets/images/gear.svg';
import { routes } from '../../../../consts';
import styles from './SettingsLink.module.css';

const SettingsLink = (): ReactElement => (
  <Link to={routes.settings}>
    <Gear className={styles.icon} />
  </Link>
);

export default SettingsLink;
