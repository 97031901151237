import { createBrowserRouter, Outlet } from 'react-router-dom';

import { routes } from './consts';
import CardDetails from './views/CardDetails/CardDetails';
import Collection from './views/Collection/Collection';
import Dashboard from './views/Dashboard';
import ErrorPage from './views/ErrorPage';
import QuizWrapper from './views/QuizWrapper';
import Settings from './views/Settings';
import PrivacyPolicy from './views/TermsPolicies/PrivacyPolicy';
import TermsOfUse from './views/TermsPolicies/TermsOfUse';
import VerifyQRCode from './views/VerifyQRCode';

const router = createBrowserRouter([
  {
    path: routes.cardsCollection,
    element: (
      <>
        <Outlet />
        <QuizWrapper />
        <VerifyQRCode />
      </>
    ),
    children: [
      {
        path: routes.cardsCollection,
        element: <Collection />,
      },
      {
        path: `${routes.cards}/:cardId`,
        element: <CardDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: routes.settings,
        element: <Settings />,
      },
      {
        path: routes.privacyPolicies,
        element: <PrivacyPolicy />,
      },
      {
        path: routes.termsOfUse,
        element: <TermsOfUse />,
      },
      {
        path: routes.dashboard,
        element: <Dashboard />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
]);

export default router;
