import { Achievement, AchievementTypes, GameDetails } from '../../../../types';

export const MOCK_ACHIEVEMENT: {
  collectorLose: Achievement;
  collectorWin: Achievement;
  masterOfLuckLose: Achievement;
  masterOfLuckWin: Achievement;
  welcomeBonus: Achievement;
} = {
  collectorLose: {
    title: 'Колекціонер',
    description: 'Збери всі 28 карток з колекції та отримай свій приз',
    type: AchievementTypes.ALL_CARDS_COLLECTED,
    completed: false,
    rewardUrl: null,
    metadata: {
      totalUsersWithFullCollection: 0,
      totalReward: 250000,
    },
  },
  collectorWin: {
    title: 'Колекціонер',
    description: 'Збери всі 28 карток з колекції та отримай свій приз',
    type: AchievementTypes.ALL_CARDS_COLLECTED,
    completed: true,
    rewardUrl: null,
    metadata: {
      totalUsersWithFullCollection: 130,
      totalReward: 250000,
    },
  },
  masterOfLuckLose: {
    title: 'Майстер удачі',
    description: 'Збери найбільшу кількість удачі конкретному гравцю та отримай 1000 USDT',
    type: AchievementTypes.GREATEST_LUCK_COLLECTED,
    completed: false,
    rewardUrl: null,
    metadata: {},
  },
  masterOfLuckWin: {
    title: 'Майстер удачі',
    description: 'Збери найбільшу кількість удачі конкретному гравцю та отримай 1000 USDT',
    type: AchievementTypes.GREATEST_LUCK_COLLECTED,
    completed: true,
    rewardUrl: null,
    metadata: {
      cardId: 1,
    },
  },
  welcomeBonus: {
    title: 'Welсome-бонус',
    description: 'Перші 10 000 учасників, які зареєструються та пройдуть верифікацію на WhiteBIT, отримають по 5 USDT.',
    type: AchievementTypes.WELCOME_BONUS,
  },
};

export const MOCK_GAME_DETAILS: {
  eventIsAvailable: GameDetails;
  eventIsFinished: GameDetails;
  gameIsFinished: GameDetails;
} = {
  eventIsAvailable: {
    eventDaysLeft: 15,
    gameDaysLeft: 40,
  },
  eventIsFinished: {
    eventDaysLeft: -1,
    gameDaysLeft: 40,
  },
  gameIsFinished: {
    eventDaysLeft: -40,
    gameDaysLeft: -1,
  },
};

export const MOCK_USER_NAMES: Record<string, string> = {
  1: 'Олександр Зінченко',
  2: 'Михайло Мудрик',
  3: 'Артем Довбик',
  4: 'Ілля Забарний',
  5: 'Віталій Миколенко',
  6: 'Віктор Циганков',
  7: 'Георгій Судаков',
  8: 'Анатолій Трубін',
  9: 'Микола Матвієнко',
  10: 'Андрій Лунін',
  11: 'Руслан Малиновський',
  12: 'Владислав Ванат',
  13: 'Георгій Бущан',
  14: 'Данило Сікан',
  15: 'Микола Шапаренко',
  16: 'Валерій Бондар',
  17: 'Олександр Піхальонок',
  18: 'Олександр Зубков',
  19: 'Юхим Конопля',
  20: 'Роман Яремчук',
  21: 'Олександр Тимчик',
  22: 'Володимир Бражко',
  23: 'Денис Попов',
  24: 'Богдан Михайличенко',
  25: 'Сергій Сидорчук',
  26: 'Максим Таловеров',
  27: 'Олександр Караваєв',
  28: 'Олексій Гуцуляк',
  29: 'Андрій Ярмоленко',
  30: 'Тарас Степаненко',
  31: 'Олександр Сваток',
};
