import React, { ReactElement, useMemo } from 'react';

import { ReactComponent as LucksIcon } from '../../../../assets/images/luckStar.svg';
import { ReactComponent as UsersIcon } from '../../../../assets/images/usersIcon.svg';
import { Dashboard } from '../../../../types';
import { splitBigIntValue, thousandsFormat } from '../../../../utils';
import styles from './AllUsers.module.css';

type Props = {
  data?: Dashboard;
};

const AllUsers = ({ data }: Props): ReactElement => {
  const luckScore = data?.totalLuck || '0';

  const [base, exp] = useMemo(() => splitBigIntValue(luckScore), [data?.totalLuck]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.count}>
        <div>
          <UsersIcon />
        </div>

        <div>
          <p className={styles.countText}>{thousandsFormat(data?.totalUsers.toFixed())}</p>
          <p className={styles.description}>Вболівальників</p>
        </div>
      </div>
      <div className={styles.count}>
        <div>
          <LucksIcon />
        </div>

        <div>
          {exp ? (
            <p className={styles.countText}>
              {base} × 10<span>{exp}</span>
            </p>
          ) : (
            <p className={styles.countText}>{thousandsFormat(Number(luckScore).toFixed(0))}</p>
          )}
          <p className={styles.description}>Разом зібрали удачі для збірної</p>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
