import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Clipboard } from '../../assets/images/clipboard.svg';
import { ReactComponent as Messages } from '../../assets/images/messages.svg';
import { ReactComponent as Shield } from '../../assets/images/shield.svg';
import Button from '../../components/Button/Button';
import Title, { TitleTypes } from '../../components/Title/Title';
import { routes, WHITE_BIT_LINKS } from '../../consts';
import settings from './Settings.module.css';

const Settings = (): React.ReactElement => (
  <div className={settings.mainContainer}>
    <div>
      <div className={settings.headerBlock}>
        <div className={settings.title}>
          <Title type={TitleTypes.h1} text="Налаштування" />
        </div>
      </div>
      <div>
        <div className={settings.menuBorder} />
        <Link to={routes.privacyPolicies}>
          <div className={settings.menuItem}>
            <div className={settings.menuItemContent}>
              <Shield className={settings.icon} />
              <span className={settings.menuItemText}>Політика конфіденційності</span>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className={settings.arrow} />
          </div>
        </Link>
        <div className={settings.menuBorder} />
        <Link to={routes.termsOfUse}>
          <div className={settings.menuItem}>
            <div className={settings.menuItemContent}>
              <Clipboard className={settings.icon} />
              <span className={settings.menuItemText}>Положення та умови</span>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className={settings.arrow} />
          </div>
        </Link>
        <div className={settings.menuBorder} />
      </div>
    </div>
    <div className={settings.buttonBlock}>
      <a href={encodeURI(WHITE_BIT_LINKS.support)}>
        <Button text="Служба підтримки" icon={<Messages className={settings.supportIcon} />} />
      </a>
    </div>
  </div>
);

export default Settings;
