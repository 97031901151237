import cn from 'classnames';
import React, { MouseEventHandler } from 'react';

import styles from './Button.module.css';

type ButtonProps = {
  text: string;
  icon?: React.ReactElement;
  classNames?: string;
  textClassNames?: string;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
};

const Button = ({ text, classNames = '', textClassNames = '', icon, onClick, disabled }: ButtonProps) => (
  <button className={cn(styles.container, classNames)} onClick={onClick} disabled={disabled}>
    <span className={cn(styles.text, textClassNames)}>{text}</span>
    {icon && <div className={styles.iconWrapper}>{icon}</div>}
  </button>
);

export default Button;
