import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setHasOpenedModals } from '../../../../store/hasOpenedModals';
import Main from './Main';
import StartButton from './StartButton';

const QRScanner = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleModal = () => {
    dispatch(setHasOpenedModals(!isOpen));
    setIsOpen(!isOpen);
  };

  return (
    <>
      <StartButton onClick={handleToggleModal} />
      {isOpen && <Main onClose={handleToggleModal} />}
    </>
  );
};

export default QRScanner;
