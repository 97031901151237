import classNames from 'classnames';
import cn from 'classnames';
import React, { memo, ReactElement, useEffect, useState } from 'react';

import HappyCardType from '../../../../assets/images/happyCardType.png';
import { Card } from '../../../../types';
import styles from './CardItem.module.css';

type CardItemProps = {
  card: Card;
};

const CardItem = ({ card }: CardItemProps): ReactElement => {
  const [mainPlayer, setMainPlayer] = useState<string>('');
  const [leftPlayer, setLeftPlayer] = useState<string>('');
  const [rightPlayer, setRightPlayer] = useState<string>('');

  const { unlocked } = card;
  const { name, position, tshirtNumber, photoUrl, id } = card.card;
  const [firstName, lastName] = name.split(' ');

  const playersArray: Record<number, number[]> = {
    29: [30, 31],
    30: [29, 31],
    31: [30, 29],
  };

  useEffect(() => {
    if (id > 28) {
      const idLeftPlayer = playersArray[id]?.[1];
      const idRightPlayer = playersArray[id]?.[0];

      import(`../../../../assets/players/${idLeftPlayer}.png`).then((data) => {
        setLeftPlayer(data.default);
      });
      import(`../../../../assets/players/${id}.png`).then((data) => {
        setMainPlayer(data.default);
      });
      import(`../../../../assets/players/${idRightPlayer}.png`).then((data) => {
        setRightPlayer(data.default);
      });
    }
  }, [id]);

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.container, { [styles.blocked]: !unlocked })}>
        {photoUrl ? (
          <img src={`${process.env.PUBLIC_URL}/images/${photoUrl}`} className={styles.image} alt="player photo" />
        ) : (
          <div className={styles.animatedCardWrapper}>
            <div className={styles.cardTypeWrapper}>
              <img src={HappyCardType} alt="happy card type" />
            </div>
            <div className={styles.players}>
              <div
                className={cn(styles.mainPlayer, styles.notAnimated)}
                style={{ backgroundImage: `url("${mainPlayer}")` }}
              />
              <div>
                <div
                  className={cn(styles.leftPlayer, styles.notAnimated)}
                  style={{ backgroundImage: `url("${rightPlayer}")` }}
                />
                <div
                  className={cn(styles.rightPlayer, styles.notAnimated)}
                  style={{ backgroundImage: `url("${leftPlayer}")` }}
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.textBlock}>
          <div className={styles.bio}>
            <div className={styles.name}>
              <p>{firstName}</p>
              <p>{lastName}</p>
            </div>
            <span className={styles.number}>{tshirtNumber}</span>
          </div>
          <p className={styles.position}>{position}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(CardItem);
