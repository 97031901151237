import { ReactElement } from 'react';

import { MOCK_USER_NAMES } from '../../views/Dashboard/components/Achievement/mockData';

type QuizResult = {
  text: string;
  description: string | ReactElement;
};

export const GetQuizResult = (id: number | undefined, onClick: () => void): QuizResult => {
  if (id) {
    return {
      text: 'Вітаємо! Ваша відповідь правильна',
      description: (
        <span>
          Ви отримали картку
          <br />
          <u onClick={onClick}>{MOCK_USER_NAMES[id]}</u>
        </span>
      ),
    };
  }

  return {
    text: 'Нажаль відповідь не правильна',
    description: 'Спробуйте трохи пізніше.',
  };
};
