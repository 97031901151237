import QrScanner from 'qr-scanner';
import React, { useEffect, useRef, useState } from 'react';

import qrcode from '../../../../../assets/images/qrcode.png';
import { CAMERA_IS_NOT_ALLOWED } from '../../../../../consts';
import Button from '../../../../Button/Button';
import Popup from '../../../../Popup';
import Spinner from '../../../../Spinner/Spinner';
import styles from './Scanner.module.css';

type NimiqQRScannerProps = {
  onClose: () => void;
  onSuccess: (res: string) => void;
};

const NimiqQRScanner = ({ onClose, onSuccess }: NimiqQRScannerProps): React.ReactElement => {
  const currentScannerRef = useRef<QrScanner>();
  const scannerRef = useRef<HTMLVideoElement | null>(null);
  const maskRef = useRef<HTMLDivElement | null>(null);
  const [hasScannerStarted, setHasScannerStarted] = useState<boolean>(false);
  const [hasAllowedCamera, setHasAllowedCamera] = useState<boolean>(true);

  const handleClose = () => {
    onClose();
    currentScannerRef.current?.destroy();
  };

  const handleSuccess = ({ data }: { data: string }) => {
    if (data) {
      onSuccess(data);
      currentScannerRef.current?.destroy();
    }
  };

  useEffect(() => {
    const checkCamera = async () => {
      const hasCamera = await QrScanner.hasCamera();

      if (!hasCamera) {
        handleClose();
      }

      const [requestedCamera] = await QrScanner.listCameras(true);

      if (!requestedCamera.id) {
        setHasAllowedCamera(false);
        return;
      }

      if (scannerRef.current && !currentScannerRef.current) {
        currentScannerRef.current = new QrScanner(scannerRef.current, handleSuccess, {
          returnDetailedScanResult: true,
          maxScansPerSecond: 2,
          preferredCamera: 'environment',
        });

        currentScannerRef.current
          .start()
          .then(() => {
            const videoWidth = scannerRef.current!.clientWidth;
            const videoHeight = scannerRef.current!.clientHeight;
            const scanAreaWidth = (videoWidth * 2) / 3;
            const maskXPosition = (videoWidth - scanAreaWidth) / 2;
            const maskYPosition = (videoHeight - scanAreaWidth) / 2;
            maskRef.current!.style.top = `${maskYPosition}px`;
            maskRef.current!.style.left = `${maskXPosition}px`;
            maskRef.current!.style.width = `${scanAreaWidth}px`;
            maskRef.current!.style.height = `${scanAreaWidth}px`;
            setHasScannerStarted(true);
          })
          .then(() => currentScannerRef.current?.hasFlash())
          .then((hasFlash?: boolean) => {
            if (hasFlash) {
              return currentScannerRef.current?.toggleFlash();
            }
          });
      }
    };

    checkCamera();
  }, []);

  if (!hasAllowedCamera) {
    return (
      <Popup
        isOpen
        isError
        imageSource={qrcode}
        titleText="Увага"
        messageText={CAMERA_IS_NOT_ALLOWED}
        buttonText="Закрити"
        onClose={handleClose}
        onClickButton={handleClose}
        lightTheme={false}
      />
    );
  }

  return (
    <div className={styles.overlay}>
      <video className={styles.videoContent} ref={scannerRef} />
      <div className={styles.mask} ref={maskRef} />
      <div className={styles.buttonContainer}>
        <Button text="Закрити" onClick={handleClose} />
      </div>
      {!hasScannerStarted && (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default NimiqQRScanner;
