import React, { ReactElement } from 'react';

import welcomeBonus from '../../../../../../assets/images/welcomeBonus.png';
import styles from './WelcomeBonus.module.css';

const WelcomeBonus = (): ReactElement => (
  <div className={styles.imageWrapper} data-testid="welcomeBonus">
    <img src={welcomeBonus} alt="welcome bonus" />
  </div>
);

export default WelcomeBonus;
