import { useEffect } from 'react';

export const useBlurBackground = (isOpen: boolean): void => {
  useEffect(() => {
    if (isOpen) {
      const root = document.querySelector('#root') as HTMLElement;
      root.style.filter = 'blur(5px)';

      return () => {
        root.style.filter = 'none';
      };
    }
  }, [isOpen]);
};
