import React, { ReactElement } from 'react';

import { ReactComponent as StarPlayer } from '../../../../../../assets/images/starPlayer.svg';
import { Metadata } from '../../../../../../types';
import styles from '../../Achievement.module.css';

type Props = {
  showElement: boolean;
  metadata?: Metadata;
  userNames?: Record<string, string>;
};

const MasterOfLuck = ({ showElement, metadata, userNames }: Props): ReactElement | null => {
  if (!showElement) {
    return null;
  }

  return (
    <div className={styles.achievementInfo} data-testid="masterOfLuck">
      <p>
        <span>Ви зібрали найбільше удачі по картці:</span>
      </p>
      <div className={styles.mainTextWrapper}>
        <StarPlayer />
        <p className={styles.playerText}>{metadata?.cardId && userNames?.[metadata.cardId.toString()]}</p>
      </div>
    </div>
  );
};

export default MasterOfLuck;
