import cn from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';

import ball from '../../../../assets/images/tapBall.png';
import { ReactComponent as Logo } from '../../../../assets/images/Ukraine_logo.svg';
import { CardTypes } from '../../../../consts';
import styles from './TapCard.module.css';

type TapCardProps = {
  id: number;
  type: CardTypes;
  openedTapSession: boolean;
  handleOpenTapSession?: () => void;
  handleClickTap?: () => void;
};

const TapCard = ({ id, type, openedTapSession, handleOpenTapSession, handleClickTap }: TapCardProps): ReactElement => {
  const [mainPlayer, setMainPlayer] = useState<string>('');
  const [leftPlayer, setLeftPlayer] = useState<string>('');
  const [rightPlayer, setRightPlayer] = useState<string>('');

  const backgroundColor: string[] | undefined = {
    [CardTypes.basic]: ['#E1E4EC', '#E1E4EC'],
    [CardTypes.legendary]: ['#323268', '#181834'],
    [CardTypes.epic]: ['#FECC0B', '#FE9D0B'],
    [CardTypes.happy]: ['#D3E058', '#D3E058'],
  }[type];

  const cardText: string | undefined = {
    [CardTypes.basic]: 'Звичайна картка',
    [CardTypes.legendary]: 'Легендарна картка',
    [CardTypes.epic]: 'Епічна картка',
    [CardTypes.happy]: 'Щаслива заміна',
  }[type];

  const cardCircleColor: string | undefined = {
    [CardTypes.basic]: '#646A9B',
    [CardTypes.legendary]: '#24244D',
    [CardTypes.epic]: '#FECC0B',
    [CardTypes.happy]: '#D3E058',
  }[type];

  const playersArray: Record<number, number[]> = {
    1: [3, 2],
    2: [3, 1],
    3: [4, 2],
    28: [26, 27],
    29: [30, 31],
    30: [29, 31],
    31: [30, 29],
  };

  useEffect(() => {
    const idLeftPlayer = playersArray[id]?.[1] || id - 1;
    const idRightPlayer = playersArray[id]?.[0] || id + 1;

    import(`../../../../assets/players/${idLeftPlayer}.png`).then((data) => {
      setLeftPlayer(data.default);
    });
    import(`../../../../assets/players/${id}.png`).then((data) => {
      setMainPlayer(data.default);
    });
    import(`../../../../assets/players/${idRightPlayer}.png`).then((data) => {
      setRightPlayer(data.default);
    });
  }, [id]);

  return (
    <div className={styles.imageContainer}>
      <div
        className={styles.animatedCardWrapper}
        style={{ background: `linear-gradient(${backgroundColor[0]}, ${backgroundColor[1]})` }}
      >
        <div className={styles.cardTypeWrapper}>
          <div className={styles.cardType}>
            <div className={styles.typeText}>{cardText}</div>
            <div className={styles.circle} style={{ backgroundColor: cardCircleColor }} />
          </div>
          <div className={styles.logoWrapper}>
            <Logo />
          </div>
        </div>
        <div className={styles.players}>
          <div
            className={cn(styles.mainPlayer, { [styles.notAnimated]: openedTapSession })}
            style={{ backgroundImage: `url("${mainPlayer}")` }}
          />
          <div>
            <div
              className={cn(styles.leftPlayer, { [styles.notAnimated]: openedTapSession })}
              style={{ backgroundImage: `url("${rightPlayer}")` }}
            />
            <div
              className={cn(styles.rightPlayer, { [styles.notAnimated]: openedTapSession })}
              style={{ backgroundImage: `url("${leftPlayer}")` }}
            />
          </div>
        </div>
      </div>
      <div className={cn(styles.tapWrapper, { [styles.tapProcess]: openedTapSession })}>
        <div className={styles.tapButton}>
          <img src={ball} alt="tap ball" onClick={handleOpenTapSession || handleClickTap} />
        </div>
        {!openedTapSession && (
          <div className={styles.tapButtonText}>
            <p>Тисни на удачу</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TapCard;
