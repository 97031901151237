import React, { Fragment, memo, ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api, { useGetDashboardInfoQuery, useGetGameDetailsQuery, useGetUserDetailsQuery } from '../../api';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer';
import Loader from '../../components/Footer/components/QRScanner/Loader/Loader';
import { AppDispatch } from '../../store';
import { AchievementTypes } from '../../types';
import Preloader from '../Preloader';
import Achievement from './components/Achievement';
import AllUsers from './components/AllUsers';
import CardsCount from './components/CardsCount';
import styles from './Dashboard.module.css';

const Dashboard = (): ReactElement => {
  const dispatch = useDispatch() as AppDispatch;
  const { status, data: cards } = useSelector(api.endpoints.getAllCards.select());
  const userDetails = useGetUserDetailsQuery();
  const gameDetails = useGetGameDetailsQuery();
  const dashboardInfo = useGetDashboardInfoQuery();

  const userNames = useMemo(
    () => cards?.reduce((acc, curr) => ({ ...acc, [curr.card.id]: curr.card.name }), {}),
    [cards]
  );

  useEffect(() => {
    gameDetails.refetch();
    userDetails.refetch();
    dashboardInfo.refetch();

    if (status === 'uninitialized') {
      setTimeout(() => dispatch(api.endpoints.getAllCards.initiate()), 2000);
    }
  }, []);

  if (status === 'uninitialized' || status === 'pending') {
    return <Preloader />;
  }

  if (userDetails.isLoading || gameDetails.isLoading || dashboardInfo.isLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <Container className={styles.container}>
      <CardsCount collectedCount={userDetails.data?.cardsCollected} />
      <AllUsers data={dashboardInfo.data} />
      {userDetails.data?.achievements?.map((item, index) => (
        <Fragment key={index}>
          <Achievement {...item} type={item.type as AchievementTypes} game={gameDetails.data} userNames={userNames} />
        </Fragment>
      ))}
      <Achievement
        title="Welсome-бонус"
        description="Перші 10 000 учасників, які зареєструються та пройдуть верифікацію на WhiteBIT, отримають по 5 USDT."
        type={AchievementTypes.WELCOME_BONUS}
        game={gameDetails.data}
      />
      <Footer />
    </Container>
  );
};

export default memo(Dashboard);
