import React, { ReactElement } from 'react';

import { ReactComponent as Circle } from '../../../../assets/images/colored_circle.svg';
import { ReactComponent as Plus } from '../../../../assets/images/colored_plus.svg';
import styles from './TapCount.module.css';

type TapCardProps = {
  tapCount: number;
};

const TapCount = ({ tapCount }: TapCardProps): ReactElement => {
  const countValue = (tapCount * 0.1).toFixed(1);
  const percentage = tapCount * 1.07;

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <div className={styles.count}>+{countValue}</div>
        <div
          className={styles.progressBar}
          style={{
            background: `linear-gradient(transparent ${76 - percentage}%, #fecc0b ${percentage - 76}%)`,
          }}
        >
          +{countValue}
        </div>
        {tapCount >= 50 && (
          <div className={styles.confetti}>
            <Circle className={styles.yellowSmallCircle} />
            <Circle className={styles.yellowMediumCircle} />
            <Plus className={styles.blueBigPlus} />
            <Plus className={styles.blueSmallPlus} />
            <Circle className={styles.yellowBigCircle} />
            <Circle className={styles.blueMediumCircle} />
            <Plus className={styles.yellowBigPlus} />
          </div>
        )}
      </header>
    </div>
  );
};

export default TapCount;
