import React, { ReactElement } from 'react';

import cup from '../../../../../../assets/images/goblet.png';
import styles from './CollectorCup.module.css';

const CollectorCup = (): ReactElement => (
  <div className={styles.imageWrapper} data-testid="collectorCup">
    <img src={cup} alt="collector cup" />
  </div>
);

export default CollectorCup;
