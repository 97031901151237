import React, { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from '../../consts';
import CollectionButton from './components/CollectionButton';
import QRScanner from './components/QRScanner';
import RatingButton from './components/RatingButton';
import styles from './Footer.module.css';

const Footer = (): ReactElement => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isDashboardPage = pathname === routes.dashboard;
  const isCollectionPage = pathname === routes.cardsCollection;

  const navigateToDashboard = () => {
    if (isCollectionPage) {
      navigate(routes.dashboard);
    }
  };

  const navigateToCardCollection = () => {
    if (isDashboardPage) {
      navigate(routes.cardsCollection);
    }
  };

  return (
    <footer className={styles.wrapper}>
      <div className={styles.background} />
      <div className={styles.container}>
        <CollectionButton active={isCollectionPage} navigateToCollectionPage={navigateToCardCollection} />
        <div className={styles.qrcodeBlock}>
          <QRScanner />
        </div>
        <RatingButton active={isDashboardPage} navigateToDashboardPage={navigateToDashboard} />
      </div>
    </footer>
  );
};

export default Footer;
