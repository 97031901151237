import React, { ReactElement, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import TechnicalModal from './components/TechnicalModal';
import router from './router';

const App = (): ReactElement => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      localStorage.setItem('token', token);
    }
  }, []);

  if (process.env.REACT_APP_TECHNICAL_WORK_IN_PROGRESS === 'true') {
    return <TechnicalModal />;
  }

  return <RouterProvider router={router} />;
};

export default App;
