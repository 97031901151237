import cn from 'classnames';
import React from 'react';

import styles from './Title.module.css';

export enum TitleTypes {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
}

type TitleProps = {
  type: TitleTypes;
  classNames?: string;
  text: string;
};

function Title({ type, text, classNames = '' }: TitleProps) {
  const ComponentType = type;
  return <ComponentType className={cn(styles.title, styles[type], classNames)}>{text}</ComponentType>;
}

export default Title;
