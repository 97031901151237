import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ShowInfoModalState = {
  value: boolean;
  delay: number;
};

const initialState: ShowInfoModalState = {
  value: false,
  delay: 5000,
};

const showQuizModal = createSlice({
  name: 'showQuizModal',
  initialState,
  reducers: {
    setShowQuizModal(state, action: PayloadAction<ShowInfoModalState>) {
      state.value = action.payload.value;
      state.delay = action.payload.delay;
    },
  },
});

export const { setShowQuizModal } = showQuizModal.actions;

export default showQuizModal.reducer;
