import React, { ReactElement } from 'react';

import { ReactComponent as Home } from '../../../../assets/images/home.svg';
import styles from './CollectionButton.module.css';

type Props = {
  active: boolean;
  navigateToCollectionPage: () => void;
};

const CollectionButton = ({ active, navigateToCollectionPage }: Props): ReactElement => (
  <div className={active ? styles.active : styles.container} onClick={navigateToCollectionPage}>
    <Home />
    <p className={styles.text}>Колекція</p>
  </div>
);

export default CollectionButton;
