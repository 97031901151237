import React from 'react';

import styles from './Header.module.css';

type HeaderProps = {
  children: React.ReactNode;
  isBlurComponent?: boolean;
};

const Header = ({ children, isBlurComponent = false }: HeaderProps) => (
  <header className={styles[isBlurComponent ? 'blurContainer' : 'container']}>{children}</header>
);

export default Header;
