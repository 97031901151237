import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetQuizQuestionQuery } from '../../api';
import Quiz from '../../components/Quiz';
import { RootState } from '../../store';
import { setShowQuizModal } from '../../store/showQuizModal';

const QuizWrapper = () => {
  const dispatch = useDispatch();
  const { data: quizData, refetch } = useGetQuizQuestionQuery();
  const showInfoModal = useSelector<RootState>((state: RootState) => state.showInfoModal.value);
  const showInfoModalDelay = useSelector<RootState>((state: RootState) => state.showInfoModal.delay);
  const hasOpenedModals = useSelector<RootState>((state: RootState) => state.hasOpenedModals.value);
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    if (showInfoModal && quizData?.question) {
      setTimeout(
        () => {
          setShowModal(true);
        },
        Number(showInfoModalDelay || 5000)
      );
    }
  }, [showInfoModal, quizData]);

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(setShowQuizModal({ value: false, delay: 5000 }));
    refetch();
  };

  if (showModal && !hasOpenedModals) {
    return <Quiz quizData={quizData} isOpen={showModal && !hasOpenedModals} onClose={handleCloseModal} />;
  }

  return <></>;
};

export default QuizWrapper;
