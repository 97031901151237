import React, { ReactElement } from 'react';

import { ReactComponent as Background } from '../../../../assets/images/goldBlurBackground.svg';
import Album from '../../../../assets/images/playersAlbum.png';
import Button from '../../../../components/Button/Button';
import styles from './AlbumBanner.module.css';

const AlbumBanner = (): ReactElement => {
  const handleClick = () => {
    window.open('https://fb-shop-2024.com.ua');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <p className={styles.text}>Розіграш альбомів з автографами гравців збірної</p>
        <Button
          textClassNames={styles.buttonText}
          classNames={styles.buttonWrapper}
          onClick={handleClick}
          text="Хочу альбом"
        />
      </div>
      <div>
        <Background />
        <img src={Album} alt="Album" />
      </div>
    </div>
  );
};

export default AlbumBanner;
