import { configureStore } from '@reduxjs/toolkit';

import api from '../api';
import hasOpenedModals from './hasOpenedModals';
import showedEmptyCards from './showedEmptyCards';
import showInfoModal from './showQuizModal';
import tokenReducer from './tokenSlice';

const store = configureStore({
  reducer: {
    token: tokenReducer,
    showedEmptyCards,
    showInfoModal,
    hasOpenedModals,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
