import React, { ReactElement } from 'react';

import { ReactComponent as Cup } from '../../../../../../assets/images/cup.svg';
import styles from './WinnersListButton.module.css';
import Button from '../../../../../../components/Button/Button';

const WinnersListButton = (): ReactElement => {
  const handleClickButton = () => {
    window.open('https://ntiloyalty.com/football2024/fb-final/udacha-lideri.html', '_blank');
  };

  return (
    <div className={styles.buttonWrapper}>
      <Button
        text="Список лідерів"
        icon={<Cup />}
        onClick={handleClickButton}
        classNames={styles.button}
        textClassNames={styles.text}
      />
    </div>
  );
};

export default WinnersListButton;
