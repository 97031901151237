export const PLAYER_DETAILS: Record<
  number,
  {
    height: string;
    weight: string;
    dateOfBirth: string;
    city: string;
  }
> = {
  1: {
    height: '175 см',
    weight: '65 кг',
    dateOfBirth: '15.12.1996',
    city: 'Радомишль',
  },
  2: {
    height: '175 см',
    weight: '61 кг',
    dateOfBirth: '05.01.2001',
    city: 'Красноград (Харківська область)',
  },
  3: {
    height: '185 см',
    weight: '78 кг',
    dateOfBirth: '21.06.1997',
    city: 'Черкаси',
  },
  4: {
    height: '186 см',
    weight: '80 кг',
    dateOfBirth: '01.09.2002',
    city: 'Київ',
  },
  5: {
    height: '180 см',
    weight: '71 кг',
    dateOfBirth: '29.05.1999',
    city: 'Черкаси',
  },
  6: {
    height: '175 см',
    weight: '70 кг',
    dateOfBirth: '15.11.1997',
    city: 'Нагарія (Ізраїль)',
  },
  7: {
    height: '177 см',
    weight: '68 кг',
    dateOfBirth: '01.09.2002',
    city: 'Брянка',
  },
  8: {
    height: '199 см',
    weight: '86 кг',
    dateOfBirth: '01.08.2001',
    city: 'Донецьк',
  },
  9: {
    height: '182 см',
    weight: '74 кг',
    dateOfBirth: '02.05.1996',
    city: 'Саки',
  },
  10: {
    height: '191 см',
    weight: '80 кг',
    dateOfBirth: '11.02.1999',
    city: 'Красноград',
  },
  11: {
    height: '181 см',
    weight: '79 кг',
    dateOfBirth: '04.05.1993',
    city: 'Житомир',
  },
  12: {
    height: '182 см',
    weight: '69 кг',
    dateOfBirth: '04.01.2002',
    city: "Кам'янець-Подільський",
  },
  13: {
    height: '196 см',
    weight: '93 кг',
    dateOfBirth: '31.05.1994',
    city: 'Одеса',
  },
  14: {
    height: '187 см',
    weight: '73 кг',
    dateOfBirth: '16.04.2001',
    city: 'Житомир',
  },
  15: {
    height: '178 см',
    weight: '72 кг',
    dateOfBirth: '04.10.1998',
    city: 'Велика Новосілка (Донецька область)',
  },
  16: {
    height: '184 см',
    weight: '73 кг',
    dateOfBirth: '27.02.1999',
    city: 'Харків',
  },
  17: {
    height: '179 см',
    weight: '72 кг',
    dateOfBirth: '07.05.1997',
    city: 'Донецьк',
  },
  18: {
    height: '182 см',
    weight: '78 кг',
    dateOfBirth: '03.08.1996',
    city: 'Макіївка',
  },
  19: {
    height: '180 см',
    weight: '74 кг',
    dateOfBirth: '26.08.1999',
    city: 'Донецьк',
  },
  20: {
    height: '191 см',
    weight: '78 кг',
    dateOfBirth: '27.11.1995',
    city: 'Львів',
  },
  21: {
    height: '180 см',
    weight: '67 кг',
    dateOfBirth: '20.01.1997',
    city: 'Крикливець',
  },
  22: {
    height: '184 см',
    weight: '77 кг',
    dateOfBirth: '23.01.2002',
    city: 'Запоріжжя',
  },
  23: {
    height: '185 см',
    weight: '81 кг',
    dateOfBirth: '17.02.1999',
    city: 'Мігія',
  },
  24: {
    height: '175 см',
    weight: '70 кг',
    dateOfBirth: '21.03.1997',
    city: 'Бориспіль',
  },
  25: {
    height: '185 см',
    weight: '70 кг',
    dateOfBirth: '02.05.1991',
    city: 'Запоріжжя',
  },
  26: {
    height: '195 см',
    weight: '86 кг',
    dateOfBirth: '28.06.2000',
    city: 'Донецьк',
  },
  27: {
    height: '174 см',
    weight: '72 кг',
    dateOfBirth: '02.06.1992',
    city: 'Херсон',
  },
  28: {
    height: '188 см',
    weight: '81 кг',
    dateOfBirth: '25.12.1997',
    city: 'Красилів (Хмельницька область)',
  },
  29: {
    height: '187 см',
    weight: '76 кг',
    dateOfBirth: '23.10.1989',
    city: 'Санкт-Петербург',
  },
  30: {
    height: '181 см',
    weight: '76 кг',
    dateOfBirth: '08.08.1989',
    city: 'Велика Новосілка',
  },
  31: {
    height: '185 см',
    weight: '78 кг',
    dateOfBirth: '27.09.1994',
    city: "Кам'янське",
  },
};
