import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import ball from '../../assets/images/ball.png';
import { ReactComponent as Home } from '../../assets/images/home.svg';
import Button from '../../components/Button/Button';
import Container from '../../components/Container/Container';
import styles from './ErrorPage.module.css';

const ErrorPage = (): ReactElement => (
  <div className={styles.backgroundWrapper}>
    <Container>
      <div className={styles.grid}>
        <div className={styles.mainContent}>
          <h3 className={styles.title}>!&?#!</h3>
          <img src={ball} alt="ball" className={styles.image} />
          <p className={styles.message}>На жаль, сталася помилка</p>
        </div>
        <Link to="/">
          <Button text="Назад до колекції" icon={<Home className={styles.icon} />} />
        </Link>
      </div>
    </Container>
  </div>
);

export default ErrorPage;
