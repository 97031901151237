import React from 'react';

import styles from './Container.module.css';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  isBlurComponent?: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
};

const Container = ({ children, className, isBlurComponent, innerRef }: ContainerProps) => (
  <div className={`${className} ${styles[isBlurComponent ? 'blurContainer' : 'container']}`} ref={innerRef}>
    {children}
  </div>
);

export default Container;
