import { useLottie } from 'lottie-react';
import React, { ReactElement } from 'react';

import animationData from '../../assets/lottie/animationData.json';
import styles from './Preloader.module.css';

const Preloader = (): ReactElement => {
  const { View } = useLottie({
    animationData: animationData,
    loop: true,
    className: styles.lottie,
  });

  return <div className={styles.wrapper}>{View}</div>;
};

export default Preloader;
