import React from 'react';

import Spinner from '../../../../../components/Spinner/Spinner';
import styles from './Loader.module.css';

const Loader = (): React.JSX.Element => (
  <div className={styles.container}>
    <Spinner />
  </div>
);

export default Loader;
