import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import luckStar from '../../../../assets/images/luckStar.svg';
import { splitBigIntValue } from '../../../../utils';
import styles from './LuckScore.module.css';
import { thousandsFormat } from './utils';

type LuckScoreProps = {
  score?: string;
};

const LuckScore = ({ score = '100' }: LuckScoreProps): ReactElement => {
  const isScoreInfinity = Number(score) === Infinity;
  const isBillionValue = Number(score) >= 1e9;

  if (isScoreInfinity || isBillionValue) {
    const [base, exp] = useMemo(() => splitBigIntValue(score), [score]);

    return (
      <div className={styles.container}>
        <div>
          <img src={luckStar} alt="luck score" />
        </div>
        <div className={styles.scoreWrapper}>
          <span className={styles.text}>
            {exp ? (
              <>
                {base} × 10<span className={styles.superCaseText}>{exp}</span>
              </>
            ) : (
              <span className={styles.countText}>{thousandsFormat(+score)}</span>
            )}
          </span>
        </div>
      </div>
    );
  }

  const { cardId } = useParams();
  const currentScore = Number(score);
  const [previousScore, setPreviousScore] = useState<number>(currentScore);

  useEffect(() => {
    setTimeout(() => {
      if (currentScore > previousScore) {
        setPreviousScore((prevValue) => prevValue + 0.1);
      }
    }, 20);
  }, [previousScore, score, cardId]);

  const isScoreInChange: boolean = !!score && currentScore > previousScore;

  return (
    <div className={styles.container}>
      <div>
        <img src={luckStar} alt="luck score" />
      </div>
      <div className={styles.scoreWrapper}>
        <span className={styles.text}>
          <span className={styles.countText} style={{ opacity: +!isScoreInChange }}>
            {thousandsFormat(currentScore)}
          </span>
        </span>
        {isScoreInChange && <span className={styles.animatedText}>{thousandsFormat(previousScore)}</span>}
      </div>
    </div>
  );
};

export default LuckScore;
