import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import goblet from '../../../../assets/images/goblet.png';
import qrcode from '../../../../assets/images/qrcode.png';
import {
  ALL_CARDS_COLLECTED_MESSAGE,
  OVERLOADED_TEXT,
  QR_CODE_SCAN_DUPLICATED_MESSAGE,
  QR_CODE_SCAN_ERROR_MESSAGE,
  QRCodeScanStatuses,
  routes,
  WHITE_BIT_LINKS,
} from '../../../../consts';
import { setHasOpenedModals } from '../../../../store/hasOpenedModals';
import { QRCodeActivationResult } from '../../../../types';
import Popup from '../../../Popup';
import styles from './ResultSelector.module.css';

type ResultSelectorProps = {
  onClose?: () => void;
  data?: QRCodeActivationResult;
  error?: FetchBaseQueryError | SerializedError;
};

const ResultSelector = ({ data, error, onClose }: ResultSelectorProps): React.ReactElement | null => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(true);

  const handleClose = () => {
    dispatch(setHasOpenedModals(false));
    setVisible(false);
    onClose?.();
  };

  useEffect(() => {
    dispatch(setHasOpenedModals(visible));

    return () => {
      dispatch(setHasOpenedModals(false));
    };
  }, []);

  const handleOpenLink = () => {
    window.open(encodeURI(WHITE_BIT_LINKS.eventInfo), '_blank');
  };

  const handleRedirectToDetails = () => {
    handleClose();
    navigate(`${routes.cards}/${data?.cardId}`);
  };

  switch (true) {
    case Boolean(error) || data?.status === QRCodeScanStatuses.failed: {
      return (
        <Popup
          isError
          isOpen={visible}
          imageSource={qrcode}
          titleText="Помилка"
          messageText={QR_CODE_SCAN_ERROR_MESSAGE}
          buttonText="До колекції"
          onClose={handleClose}
          onClickButton={handleClose}
          lightTheme={false}
        />
      );
    }
    case data?.status === QRCodeScanStatuses.duplicated: {
      return (
        <Popup
          isError
          isOpen={visible}
          imageSource={qrcode}
          titleText="Помилка"
          messageText={QR_CODE_SCAN_DUPLICATED_MESSAGE}
          buttonText="До колекції"
          onClose={handleClose}
          onClickButton={handleClose}
          lightTheme={false}
        />
      );
    }
    case data?.status === QRCodeScanStatuses.allCardsCollected: {
      return (
        <Popup
          isOpen={visible}
          imageSource={goblet}
          titleText="Вітаємо!"
          messageText={
            <>
              {ALL_CARDS_COLLECTED_MESSAGE}{' '}
              <a href={encodeURI(WHITE_BIT_LINKS.eventInfo)} target="_blank">
                WhiteBIT
              </a>
            </>
          }
          buttonText="Як отримати приз?"
          onClose={handleRedirectToDetails}
          onClickButton={handleOpenLink}
        />
      );
    }
    case data?.status === QRCodeScanStatuses.overloaded: {
      return (
        <Popup
          isError
          isOpen={visible}
          titleText="Дуже Гаряче!"
          messageText={
            <>
              <p className={styles.wrapper}>{OVERLOADED_TEXT}</p>
              <p className={styles.image}>&#128293;</p>
            </>
          }
          buttonText="До колекції"
          onClose={handleClose}
          onClickButton={handleClose}
          lightTheme={false}
        />
      );
    }
    case Boolean(data?.cardId): {
      return <Navigate to={`${routes.cards}/${data?.cardId}`} />;
    }
    default:
      return null;
  }
};

export default ResultSelector;
