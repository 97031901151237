export const thousandsFormat = (value?: string) => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const splitBigIntValue = (value: string) => {
  const [count, power] = value.split('+');

  if (power) {
    return [count.slice(0, 3), power];
  }

  const [currentValue] = value.split('.');
  const first = currentValue.slice(0, 1);
  const base = currentValue.slice(1, 2);
  const exp = currentValue.length - 1;

  return [`${first}.${base}`, exp >= 9 ? exp : 0];
};

export const dayTextFormat = (value?: number): string => {
  if (typeof value !== 'number') {
    return '';
  }

  if ([11, 12, 13, 14].includes(value)) {
    return `${value} днів`;
  }

  switch (+value.toString().slice(-1)) {
    case 1: {
      return `${value} день`;
    }
    case 2:
    case 3:
    case 4: {
      return `${value} дні`;
    }

    default: {
      return `${value} днів`;
    }
  }
};

export const checkLastVisit = (): boolean => {
  const FIVE_MINUTES: number = 5 * 60 * 1000;
  const lastVisit: number = Number(localStorage.getItem('lastVisit') || 0);
  const now: number = new Date().getTime();

  if (!lastVisit || now - lastVisit > FIVE_MINUTES) {
    localStorage.setItem('lastVisit', now.toString());
    return true;
  }

  return false;
};
