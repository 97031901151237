import React, { ReactElement } from 'react';

import { ReactComponent as Cup } from '../../../../assets/images/cup.svg';
import styles from './RatingButton.module.css';

type Props = {
  active: boolean;
  navigateToDashboardPage: () => void;
};

const RatingButton = ({ active, navigateToDashboardPage }: Props): ReactElement => (
  <div className={active ? styles.active : styles.container} onClick={navigateToDashboardPage}>
    <Cup />
    <p className={styles.text}>Мої винагороди</p>
  </div>
);

export default RatingButton;
