import React, { ReactElement } from 'react';
import Modal from 'react-modal';

import { useActivateQRCodeMutation } from '../../../../api';
import styles from '../../../Popup/Popup.module.css';
import Loader from './Loader/Loader';
import NimiqQRScanner from './NimiqScanner/NimiqQRScanner';
import ResultSelector from './ResultSelector';

type MainProps = {
  onClose: () => void;
};

const Main = ({ onClose }: MainProps): ReactElement => {
  const [activateQRCode, { data, error, isLoading }] = useActivateQRCodeMutation();

  const handleScanSuccess = (scannedLink: string) => {
    const url = new URL(scannedLink);
    const code = url.searchParams.get('c') ?? '';

    activateQRCode(code);
  };

  if (isLoading) {
    return (
      <Modal isOpen className={styles.loaderContent} overlayClassName={styles.loaderWrapper} ariaHideApp={false}>
        <Loader />
      </Modal>
    );
  }

  if (data || error) {
    return <ResultSelector data={data} error={error} onClose={onClose} />;
  }

  return <NimiqQRScanner onSuccess={handleScanSuccess} onClose={onClose} />;
};

export default Main;
