import React, { ReactElement } from 'react';

import bigStar from '../../../../../../assets/images/bigStar.png';
import styles from './BigStar.module.css';

const BigStar = (): ReactElement => (
  <div className={styles.imageWrapper} data-testid="bigStar">
    <img src={bigStar} alt="big star" />
  </div>
);

export default BigStar;
