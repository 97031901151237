import React, { ReactElement } from 'react';

import { ReactComponent as People } from '../../../../../../assets/images/people.svg';
import { thousandsFormat } from '../../../../../../utils';
import styles from '../../Achievement.module.css';

type Props = {
  count?: number;
};

const CollectorsCounterBlock = ({ count }: Props): ReactElement | null => {
  if (typeof count !== 'number') {
    return null;
  }

  return (
    <div className={styles.achievementInfo} data-testid="counterBlock">
      <div className={styles.mainTextWrapper}>
        <People />
        <h2>{thousandsFormat(count.toString())}</h2>
      </div>
      <p>
        <span>Учасників зібрало всю колекцію</span>
      </p>
    </div>
  );
};

export default CollectorsCounterBlock;
