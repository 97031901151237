import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ShowedEmptyCardsState = {
  value: boolean;
};

const initialState: ShowedEmptyCardsState = {
  value: false,
};

const showedEmptyCards = createSlice({
  name: 'hasNoAnyCards',
  initialState,
  reducers: {
    setShowedEmptyState(state, action: PayloadAction<boolean>) {
      state.value = action.payload;
    },
  },
});

export const { setShowedEmptyState } = showedEmptyCards.actions;

export default showedEmptyCards.reducer;
