import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { tapClickAnalytics } from '../../../../analystic';
import { useSetTapCountMutation } from '../../../../api';
import { CardTypes } from '../../../../consts';
import TapCard from '../TapCard';
import TapCount from '../TapCount';
import styles from './TapComponent.module.css';

const INTERVAL_FOR_SENDING_TAP_COUNT = 10000;
const TIMEOUT_FOR_SEND_TAP_COUNT = 5000;
const TIMEOUT_FOR_TAP_SESSION = 2000;

type TapCardProps = {
  cardId: number;
  type: CardTypes;
  tapCount: number;
  openedTapSession: boolean;
  handleSetTapCount: (value: number) => void;
  onCloseTapSession?: () => void;
};

const TapComponent = ({
  cardId,
  type,
  tapCount,
  openedTapSession,
  handleSetTapCount,
  onCloseTapSession,
}: TapCardProps): ReactElement => {
  const [lastSentCount, setLastSentCount] = useState<number>(0);
  const [setTapCount] = useSetTapCountMutation();

  const sendTimer = useRef<NodeJS.Timeout>();
  const sessionTimer = useRef<NodeJS.Timeout>();
  const interval = useRef<NodeJS.Timeout>();
  const sendCount = useRef<VoidFunction | null>(null);

  const setSessionTimeout = () => {
    clearTimeout(sessionTimer.current);

    sessionTimer.current = setTimeout(() => {
      onCloseTapSession?.();
    }, TIMEOUT_FOR_TAP_SESSION);
  };

  const setTimeoutForSendingData = () => {
    clearTimeout(sendTimer.current);

    sendTimer.current = setTimeout(() => {
      sendCount.current?.();
      handleSetTapCount(0);
      setLastSentCount(0);
    }, TIMEOUT_FOR_SEND_TAP_COUNT);
  };

  const setIntervalForSendingData = () => {
    clearInterval(interval.current);

    interval.current = setTimeout(() => {
      sendCount.current?.();
      interval.current = undefined;
    }, INTERVAL_FOR_SENDING_TAP_COUNT);
  };

  useEffect(() => {
    sendCount.current = (): void => {
      if (tapCount >= 50 && lastSentCount !== tapCount) {
        setLastSentCount(tapCount);
        setTapCount({ tapsCount: tapCount - lastSentCount, id: cardId });

        setIntervalForSendingData();
        sendCount.current = null;
      }
    };

    if (tapCount >= 50 && !lastSentCount && !interval.current) {
      sendCount.current?.();
    }
  }, [tapCount, lastSentCount]);

  useEffect(() => {
    if (openedTapSession) {
      setSessionTimeout();
      setTimeoutForSendingData();
      setIntervalForSendingData();
    }
  }, [openedTapSession]);

  const onClick = () => {
    setSessionTimeout();
    setTimeoutForSendingData();

    handleSetTapCount(tapCount + 1);
    tapClickAnalytics();
  };

  return (
    <div className={styles[openedTapSession ? 'container' : 'hidden']}>
      <TapCount tapCount={tapCount} />
      <TapCard id={cardId} type={type} openedTapSession={openedTapSession} handleClickTap={onClick} />
    </div>
  );
};

export default TapComponent;
