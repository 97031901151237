import cn from 'classnames';
import React from 'react';
import Modal from 'react-modal';

import { ReactComponent as Close } from '../../assets/images/close.svg';
import { useBlurBackground } from '../../hooks/useBlurBackground';
import Button from '../Button/Button';
import Title, { TitleTypes } from '../Title/Title';
import styles from './Popup.module.css';

type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
  titleText: string;
  imageSource?: string;
  messageText: string | React.ReactElement;
  buttonText?: string;
  onClickButton?: () => void;
  lightTheme?: boolean;
  isError?: boolean;
};

const Popup = ({
  isOpen,
  onClose,
  imageSource,
  titleText,
  messageText,
  buttonText,
  onClickButton,
  lightTheme = true,
  isError,
}: PopupProps): React.JSX.Element => {
  useBlurBackground(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cn(styles.content, styles[lightTheme ? 'lightTheme' : 'darkTheme'])}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.closeButton} onClick={onClose}>
            <Close className={styles[isError ? 'errorClose' : '']} />
          </div>
        </div>

        <div>
          {titleText && (
            <Title type={TitleTypes.h1} text={titleText} classNames={styles[isError ? 'errorTitle' : 'title']} />
          )}
          <p className={styles[isError ? 'errorMessage' : 'message']}>{messageText}</p>
        </div>

        {imageSource && (
          <div className={styles.imageBlock}>
            <img className={styles.image} src={imageSource} alt="modal image" />
          </div>
        )}

        {buttonText && (
          <div className={styles.buttonBlock}>
            <Button
              text={buttonText}
              onClick={onClickButton}
              classNames={styles[isError ? `errorButton` : '']}
              textClassNames={styles[isError ? `errorButtonText` : '']}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Popup;
