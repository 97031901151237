import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useActivateQRCodeMutation } from '../../api';
import ResultSelector from '../../components/Footer/components/QRScanner/ResultSelector';
import { setHasOpenedModals } from '../../store/hasOpenedModals';
import { setShowQuizModal } from '../../store/showQuizModal';
import { checkLastVisit } from '../../utils';

const VerifyQRCode = (): ReactElement | null => {
  const dispatch = useDispatch();
  const [activateQRCode, { data, error }] = useActivateQRCodeMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const qrCode = searchParams.get('qr');

  useEffect(() => {
    if (qrCode) {
      setTimeout(async () => {
        try {
          await activateQRCode(qrCode).unwrap();
        } finally {
          dispatch(setHasOpenedModals(false));
          searchParams.delete('qr');
          setSearchParams(searchParams);
        }
      }, 2000);
    } else {
      if (checkLastVisit()) {
        dispatch(setHasOpenedModals(false));
        dispatch(setShowQuizModal({ value: true, delay: 4000 }));
      }
    }
  }, []);

  return <ResultSelector data={data} error={error} />;
};

export default VerifyQRCode;
