import React, { ReactElement } from 'react';

import { ReactComponent as Gift } from '../../../../../../assets/images/gift.svg';
import { WHITE_BIT_LINKS } from '../../../../../../consts';
import { AchievementTypes, Metadata } from '../../../../../../types';
import { thousandsFormat } from '../../../../../../utils';
import styles from '../../Achievement.module.css';

type Props = {
  showElement: boolean;
  type: AchievementTypes;
  metadata?: Metadata;
};

const GiftBlock = ({ showElement, type, metadata }: Props): ReactElement | null => {
  const isCollectorType = type === AchievementTypes.ALL_CARDS_COLLECTED;

  const giftText = {
    [AchievementTypes.ALL_CARDS_COLLECTED]: `до ${thousandsFormat(metadata?.totalReward?.toString())} USDT`,
    [AchievementTypes.GREATEST_LUCK_COLLECTED]: '1 000 USDT',
    [AchievementTypes.WELCOME_BONUS]: '5 USDT',
  }[type];

  if (!showElement) {
    return null;
  }

  return (
    <div className={styles.achievementInfo}>
      <div className={styles.mainTextWrapper}>
        <Gift />
        <h2>{giftText}</h2>
      </div>

      <p className={styles.whiteText}>
        від{' '}
        <a href={encodeURI(WHITE_BIT_LINKS.eventInfo)} target="_blank">
          WhiteBIT
        </a>
      </p>

      {isCollectorType && (
        <p>
          <span>Загальний призовий фонд</span>
        </p>
      )}
    </div>
  );
};

export default GiftBlock;
