import React, { ReactElement } from 'react';

import { AchievementTypes, GameDetails } from '../../../../../../types';
import { dayTextFormat } from '../../../../../../utils';
import styles from '../../Achievement.module.css';
import BigStar from '../BigStar';
import CollectorCup from '../CollectorCup';
import WelcomeBonus from '../WelcomeBonus';

type Props = {
  title: string;
  description: string;
  type: AchievementTypes;
  completed: boolean;
  eventIsActive: boolean;
  gameIsActive: boolean;
  game?: GameDetails;
};

const TitleAndDescriptionBlock = ({
  title,
  description,
  type,
  completed,
  game,
  eventIsActive,
  gameIsActive,
}: Props): ReactElement | null => {
  const isCollectorType = type === AchievementTypes.ALL_CARDS_COLLECTED;
  const isLuckyType = type === AchievementTypes.GREATEST_LUCK_COLLECTED;

  const ImageByType = {
    [AchievementTypes.ALL_CARDS_COLLECTED]: CollectorCup,
    [AchievementTypes.GREATEST_LUCK_COLLECTED]: BigStar,
    [AchievementTypes.WELCOME_BONUS]: WelcomeBonus,
  }[type];

  return (
    <div className={styles.type}>
      <ImageByType />
      <div className={styles.description} data-testid="titleAndDescription">
        <h3>{title}</h3>

        {completed && isCollectorType && eventIsActive ? (
          <p className={styles.gotCollection}>Колекцію зібрано</p>
        ) : (
          <p>
            <span>{eventIsActive ? description : 'Акція завершена'}</span>
          </p>
        )}

        {(isCollectorType || isLuckyType) && (
          <p data-testid="daysLeftInfo">
            {eventIsActive ? (
              <>
                <span>До кінця акції залишилось:</span> <br />
                <strong>{dayTextFormat(game?.eventDaysLeft)}</strong>
              </>
            ) : (
              completed &&
              gameIsActive && (
                <>
                  <span>Залишилось, щоб забрати приз:</span> <br />
                  <strong>{dayTextFormat(game?.gameDaysLeft)}</strong>
                </>
              )
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default TitleAndDescriptionBlock;
