import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import { useSendQuizAnswerMutation } from '../../api';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { useBlurBackground } from '../../hooks/useBlurBackground';
import { QuizQuestionData } from '../../types';
import Button from '../Button/Button';
import Spinner from '../Spinner/Spinner';
import styles from './Quiz.module.css';
import { GetQuizResult } from './utils';

type InfoModalsProps = {
  isOpen: boolean;
  quizData?: QuizQuestionData;
  onClose: () => void;
};

const Quiz = ({ quizData, isOpen, onClose }: InfoModalsProps): React.JSX.Element | null => {
  const navigate = useNavigate();
  const [sentAfterGettingError, setSentAfterGettingError] = useState(true);
  const [sendQuizAnswer, { data: answerData, isLoading, error }] = useSendQuizAnswerMutation();
  const [selectedAnswer, setSelectedAnswer] = useState<string>();

  useBlurBackground(isOpen);

  useEffect(() => {
    if (error && sentAfterGettingError) {
      setSentAfterGettingError(false);
      sendQuizAnswer({ answer: selectedAnswer as string, questionId: id });
    }
  }, [error]);

  if (!quizData?.question) {
    return null;
  }

  const { text: questionText, allAnswers, id } = quizData.question;

  const handleSendAnswer = () => {
    setSentAfterGettingError(true);
    sendQuizAnswer({ answer: selectedAnswer as string, questionId: id });
  };

  const handleCloseModal = (): void => {
    if (answerData?.reward?.cardId) {
      navigate(`/cards/${answerData.reward.cardId}`);
    }

    onClose();
  };

  const { text, description } = GetQuizResult(answerData?.reward?.cardId, handleCloseModal);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      className={styles.content}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <div onClick={handleCloseModal}>
              <Close />
            </div>
          </div>

          <div className={styles.modalContent}>
            <div>
              <p className={styles.message}>{answerData ? text : questionText}</p>
            </div>

            {answerData ? (
              <div className={styles.answersWrapper}>{description}</div>
            ) : (
              <>
                <div className={styles.answersWrapper}>
                  {allAnswers.map((item, index) => (
                    <div
                      key={index}
                      className={cn(styles.option, { [styles.selected]: selectedAnswer === item })}
                      onClick={() => setSelectedAnswer(item)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
                <Button text="Відповісти" disabled={!selectedAnswer} onClick={handleSendAnswer} />
                <p className={styles.bottomText}>
                  Покажи свої футбольні знання та <strong>отримай картку</strong>
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default Quiz;
