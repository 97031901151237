import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TokenState = {
  value: string | null;
};

const initialState: TokenState = {
  value: null,
};

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    saveToken(state, action: PayloadAction<string>) {
      state.value = action.payload;
    },
  },
});

export const { saveToken } = tokenSlice.actions;

export default tokenSlice.reducer;
