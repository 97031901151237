import React, { ReactElement } from 'react';

import WhiteBitBannerImage from '../../../../assets/images/whiteBitBanner.png';
import { WHITE_BIT_LINKS } from '../../../../consts';
import styles from './WhiteBitBanner.module.css';

const WhiteBitBanner = (): ReactElement => {
  const handleButtonClick = () => {
    window.open(encodeURI(WHITE_BIT_LINKS.eventInfo), '_blank');
  };

  return (
    <>
      <div className={styles.WBBannerWrapper}>
        <img src={WhiteBitBannerImage} alt="white bit banner" />
        <div className={styles.bannerText}>
          За кожного гравця з максимальною удачею{' '}
          <a href={encodeURI(WHITE_BIT_LINKS.eventInfo)} target="_blank">
            WhiteBIT
          </a>{' '}
          дає
        </div>
        <div className={styles.bannerButton} onClick={handleButtonClick}>
          <span>1000 USDT</span>
        </div>
      </div>
      <p className={styles.textUnderBanner}>
        Детальніше читай у{' '}
        <a href={encodeURI(WHITE_BIT_LINKS.rules)} target="_blank">
          Правилах
        </a>
      </p>
    </>
  );
};

export default WhiteBitBanner;
