import { CardTypes, QRCodeScanStatuses } from '../consts';

export enum AchievementTypes {
  ALL_CARDS_COLLECTED = 'ALL_CARDS_COLLECTED',
  GREATEST_LUCK_COLLECTED = 'GREATEST_LUCK_COLLECTED',
  WELCOME_BONUS = 'WELCOME_BONUS',
}

export enum QuizQuestionStatus {
  SUCCEED = 'SUCCEED',
  NO_QUESTIONS_AVAILABLE = 'NO_QUESTIONS_AVAILABLE',
}

export enum QuizAnswerStatus {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
}

export type Card = {
  card: {
    id: number;
    name: string;
    tshirtNumber: number;
    position: string;
    type: CardTypes;
    photoUrl: string;
  };
  unlocked: boolean;
};

export type QRCodeActivationResult = {
  status: QRCodeScanStatuses;
  cardId: number | null;
};

export type CardDetails = {
  luckScore: string;
};

export type TapsCount = {
  id: number;
  tapsCount: number;
};

export type Dashboard = {
  totalLuck: string;
  totalUsers: number;
};

export type Metadata = {
  cardId?: number;
  totalReward?: number;
  totalUsersWithFullCollection?: number;
};

export type Achievement = {
  title: string;
  description: string;
  type: AchievementTypes;
  rewardUrl?: string | null;
  completed?: boolean;
  metadata?: Metadata;
};

export type UserDetails = {
  phoneNumber: string;
  cardsCollected: number;
  totalCardsNumber: number;
  achievements: Achievement[];
};

export type GameDetails = {
  eventDaysLeft: number;
  gameDaysLeft: number;
};

export type QuizQuestionData = {
  status: QuizQuestionStatus;
  question: {
    id: number;
    text: string;
    allAnswers: string[];
  } | null;
};

export type QuizAnswerData = {
  status: QuizAnswerStatus;
  reward: {
    status: QRCodeScanStatuses;
    cardId: number;
  } | null;
};

export type QuizAnswerBody = {
  questionId: number;
  answer: string;
};
